
import http from "../../../../../common/services/http";
import { defineComponent, ref, reactive, onMounted, computed } from "vue";
import moment from "moment";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const headers = {
      created_at: "Created At",
      name: "Name"
    };

    const roles = ref({
      data: []
    });

    const permissions = ref({
      data: []
    });

    const role = reactive({
      permissions: [],
      name: ""
    });

    const user_permissions = ref([]);

    const search = ref("");

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    function formatDate(date: moment.MomentInput) {
      return moment(date).format("YYYY-MM-DD");
    }

    function getUserPermissions(user: any) {
      if (user) {
        user_permissions.value = user.permissions.map((a: { id: any }) => a.id);
      }
    }

    function fetchRoles(page = 1) {
      processing.value = true;

      http.get(`/api/roles?page=${page}`).then(res => {
        roles.value = res.data;
        processing.value = false;
      });
    }

    function fetchPermissions(page = 1) {
      processing.value = true;

      http.get(`/api/permissions?fetch=all`).then(res => {
        permissions.value = res.data;
        processing.value = false;
      });
    }

    function searchRoles(e: any) {
      processing.value = true;

      http.get(`/api/roles?s=${search.value}`).then(res => {
        roles.value = res.data;
        processing.value = false;
      });
    }

    function createRole() {
      http
        .post("/api/roles", role)
        .then(res => {
          if (res.data.name) {
            created.value = true;

            role.name = " ";
            role.permissions = [];
          }
        })
        .finally(() => {
          document.getElementById("hideNewForm")?.click();
          fetchRoles();
        });
    }

    function updateRole(role: any) {
      role.permissions = user_permissions;
      role._method = "PATCH";
      http
        .patch(`/api/roles/${role.id}`, {
          name: role.name,
          permissions: role.permissions
        })
        .then(res => {
          if (res.data.name) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchRoles();
        });
    }

    function deleteRole(id: any) {
      http.post(`/api/roles/${id}`, { _method: "DELETE" }).then(res => {
        fetchRoles();
      });
    }

    onMounted(() => {
      fetchRoles();
      fetchPermissions();
    });

    return {
      headers,
      fetchRoles,
      fetchPermissions,
      processing,
      roles,
      permissions,
      createRole,
      created,
      role,
      updateRole,
      updated,
      deleteRole,
      search,
      searchRoles,
      formatDate,
      getUserPermissions,
      user_permissions
    };
  }
});
