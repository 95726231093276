<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des d-print-none">
            <p>Create and view user roles</p>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Type to search..."
                  v-model="search"
                  @keyup="searchRoles"
                />
              </div>
            </li>
            <li>
              <a
                href="#newItem"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-plus-c"></em>
                <span>
                  <span class="d-none d-sm-inline-block">
                    Add
                  </span>
                  New
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="newItem">
                <div class="modal-dialog" role="document">
                  <form class="modal-content" @submit.prevent="createRole">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white" v-if="role.name">
                        Adding For {{ role?.name }}
                      </h5>
                      <h5 class="modal-title text-white" v-else>
                        Create New Role
                      </h5>
                    </div>

                    <div class="modal-body form row">
                      <div class="col-md-12 form-group">
                        <label>Name</label>
                        <input
                          class="form-control"
                          type="text"
                          v-model="role.name"
                        />
                      </div>

                      <div class="col-md-12 form-group">
                        <label class="control-label">
                          Permissions
                        </label>
                        <Multiselect
                          :options="permissions"
                          v-model="role.permissions"
                          trackBy="name"
                          label="name"
                          valueProp="id"
                          :searchable="true"
                          mode="tags"
                          placeholder="Type to search..."
                        />
                      </div>
                    </div>
                    <div class="modal-footer bg-light justify-content-end">
                      <button class="btn btn-primary float-right">
                        <em class="icon ni ni-save"></em>
                        <span>
                          Save Details
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div class="card card-bordered" v-else>
        <data-table
          :headers="headers"
          :records="roles.data"
          @delete="deleteRole"
          @edit="getUserPermissions"
        >
          <template #title="scope">
            {{ scope.record.name }}
          </template>

          <template #details="scope">
            <div
              class="card card-bordered"
              v-if="scope.record.permissions.length > 0"
            >
              <div class="card-content">
                <table class="table pmd-table table-orders">
                  <thead class="tb-odr-head">
                    <tr class="tb-odr-item">
                      <th></th>
                      <th class="text-center">
                        Permissions
                      </th>
                    </tr>
                  </thead>
                  <tbody class="tb-odr-body">
                    <tr
                      v-for="permission in scope.record.permissions"
                      :key="permission.id"
                    >
                      <th></th>
                      <td
                        class="text-center font-weight-light font-size-20 m-b-0"
                      >
                        {{ permission.name }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else>
              <h4>
                <em class="icon ni ni-alert-c text-danger mr-2"></em>
                No permissions assigned...
              </h4>
            </div>
          </template>

          <template #form="scope">
            <form class="form row" @submit.prevent="updateRole(scope.record)">
              <div class="form-group col-md-12">
                <label>Name</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="scope.record.name"
                />
              </div>
              <div class="col-md-12 form-group">
                <label class="control-label">Permissions</label>
                <Multiselect
                  :options="permissions"
                  v-model="user_permissions"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  mode="tags"
                  :searchable="true"
                  placeholder="Type to search..."
                />
              </div>
              <div class="form-group col-md-12">
                <button class="btn btn-primary float-right">
                  <em class="icon ni ni-save"></em>
                  <span> Update {{ scope.record?.name }} </span>
                </button>
              </div>
            </form>
          </template>
        </data-table>
      </div>

      <paginate
        :data="roles"
        @changed="fetchRecords"
        v-if="roles.data.length"
      />
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import { defineComponent, ref, reactive, onMounted, computed } from "vue";
import moment from "moment";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const headers = {
      created_at: "Created At",
      name: "Name"
    };

    const roles = ref({
      data: []
    });

    const permissions = ref({
      data: []
    });

    const role = reactive({
      permissions: [],
      name: ""
    });

    const user_permissions = ref([]);

    const search = ref("");

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    function formatDate(date: moment.MomentInput) {
      return moment(date).format("YYYY-MM-DD");
    }

    function getUserPermissions(user: any) {
      if (user) {
        user_permissions.value = user.permissions.map((a: { id: any }) => a.id);
      }
    }

    function fetchRoles(page = 1) {
      processing.value = true;

      http.get(`/api/roles?page=${page}`).then(res => {
        roles.value = res.data;
        processing.value = false;
      });
    }

    function fetchPermissions(page = 1) {
      processing.value = true;

      http.get(`/api/permissions?fetch=all`).then(res => {
        permissions.value = res.data;
        processing.value = false;
      });
    }

    function searchRoles(e: any) {
      processing.value = true;

      http.get(`/api/roles?s=${search.value}`).then(res => {
        roles.value = res.data;
        processing.value = false;
      });
    }

    function createRole() {
      http
        .post("/api/roles", role)
        .then(res => {
          if (res.data.name) {
            created.value = true;

            role.name = " ";
            role.permissions = [];
          }
        })
        .finally(() => {
          document.getElementById("hideNewForm")?.click();
          fetchRoles();
        });
    }

    function updateRole(role: any) {
      role.permissions = user_permissions;
      role._method = "PATCH";
      http
        .patch(`/api/roles/${role.id}`, {
          name: role.name,
          permissions: role.permissions
        })
        .then(res => {
          if (res.data.name) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchRoles();
        });
    }

    function deleteRole(id: any) {
      http.post(`/api/roles/${id}`, { _method: "DELETE" }).then(res => {
        fetchRoles();
      });
    }

    onMounted(() => {
      fetchRoles();
      fetchPermissions();
    });

    return {
      headers,
      fetchRoles,
      fetchPermissions,
      processing,
      roles,
      permissions,
      createRole,
      created,
      role,
      updateRole,
      updated,
      deleteRole,
      search,
      searchRoles,
      formatDate,
      getUserPermissions,
      user_permissions
    };
  }
});
</script>
